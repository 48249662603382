body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}


.rdt_TableHeader {
  padding: 0 !important;
}

.rdt_Table {
  .rdt_TableHead {
    font-size: 13px !important;


    .rdt_TableHeadRow {
      min-height: 40px !important;

    }
  }

  .rdt_TableRow {
    font-size: 14px !important;
    min-height: 42px !important;
  }

  .rdt_TableBody .rdt_TableCell div {
    //overflow: visible;
  }
}

.rdt_Table .rdt_TableHeadRow,
.rdt_Table .rdt_TableRow {
  position: relative;
}

.rdt_Table .rdt_TableHead .rdt_TableHeadRow .rdt_TableCol:last-child,
.rdt_Table .rdt_TableBody .rdt_TableRow .rdt_TableCell:last-child {
  position: sticky;
  top: 0;
  right: 0;
  background-color: #fff;
  /*border-left: 2px solid #e0e0e0;*/
  z-index: 10;
}

.rdt_Table .rdt_TableHead .rdt_TableHeadRow .rdt_TableCol:first-child,
.rdt_Table .rdt_TableBody .rdt_TableRow .rdt_TableCell:first-child {
  position: sticky;
  top: 0;
  left: 0;
  background-color: #fff;
  /*border-left: 2px solid #e0e0e0;*/
  z-index: 10;
}


.swal2-container {
  z-index: 10000;
}

.swal2-popup.swal2-modal.swal2-show {
  z-index: 10000;
}


.s-14 {
  font-size: 13px !important;
}

.ck.ck-content.ck-editor__editable.ck-rounded-corners.ck-editor__editable_inline.ck-blurred,
.ck.ck-content.ck-editor__editable.ck-rounded-corners.ck-editor__editable_inline.ck-focused {
  height: 150px;
}

/*override web app css*/
@media (min-width: 992px) {
  .main-content-app {
    height: calc(100vh - 165px) !important;
  }
}

/* width */
::-webkit-scrollbar {
  width: 5px !important;
  height: 5px !important;
}

/* Track */
::-webkit-scrollbar-track {
  background: #f1f1f1 !important;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #888 !important;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #555 !important;
}